import { deleteAllFailed } from './deleteAllFailed';
import { deleteAllLoading } from './deleteAllLoading';
import { deleteAllSucceed } from './deleteAllSucceed';
import { fetchDesktopDeleteAllNotifications } from '../../../repositories/notification-center/v1/desktop/delete-all-notifications';
import { TThunkAction } from '../../../types/redux';

export const deleteAll = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(deleteAllLoading());
    try {
      await fetchDesktopDeleteAllNotifications({
        httpApi,
      });

      dispatch(deleteAllSucceed());
    } catch (ex) {
      logger.error(ex, {
        domain: 'deleteAllNotifications',
      });

      dispatch(deleteAllFailed());
    }
  };
};
