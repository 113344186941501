import { TDeleteAllNotifications } from './deleteAll';
import { TGetNotificationsList } from './getNotificationsList';
import { TMarkAllAsReadNotification } from './markAllAsRead';
import { TMarkAsReadNotification } from './markAsRead';
import { EWebPushStatus } from '../../types/notifications';
import { ITypedReduxAction } from '../../types/redux/actionType';

export enum ENotificationsActionTypes {
  UpdateCount = 'notifications/update-count',
  SetWebPushStatus = 'notifications/set-web-push-status',
  OpenNotificationsModal = 'notifications/open-notifications-modal',
}

export type TUpdateNotificationsCount = ITypedReduxAction<ENotificationsActionTypes.UpdateCount, number>;
export type TSetWebPushStatus = ITypedReduxAction<ENotificationsActionTypes.SetWebPushStatus, EWebPushStatus>;
export type TOpenNotificationsModal = ITypedReduxAction<ENotificationsActionTypes.OpenNotificationsModal, boolean>;

export type TNotificationsCenterActions =
  | TDeleteAllNotifications
  | TGetNotificationsList
  | TMarkAllAsReadNotification
  | TMarkAsReadNotification
  | TUpdateNotificationsCount
  | TSetWebPushStatus
  | TOpenNotificationsModal;
