// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IDesktopMarkAsReadRequest,
  TDesktopMarkAsReadModel,
  IMappers,
  TDesktopMarkAsReadResponse,
  IDesktopMarkAsReadResponseError,
} from './types';

export const defaultConfig: TDesktopMarkAsReadModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'notification-center',
  pathApi: '/v1/desktop/mark-as-read/',
  requestType: 'json',
} as TDesktopMarkAsReadModel;

export function createDesktopMarkAsReadModel(parameters: IDesktopMarkAsReadRequest): TDesktopMarkAsReadModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IDesktopMarkAsReadOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
  parameters: IDesktopMarkAsReadRequest;
}

export async function fetchDesktopMarkAsRead<TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IDesktopMarkAsReadOptions<TResponse400>): Promise<TDesktopMarkAsReadResponse | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createDesktopMarkAsReadModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IDesktopMarkAsReadResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDesktopMarkAsReadResponse;
}
