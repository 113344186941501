import { CustomTooltip } from '@cian/ui-kit';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackingMyHomeIconClick } from './tracking';
import { getTooltipCloseFromCookie, setTooltipCloseCookies } from './utils';
import { MyHomeLinkLayout } from '../../components/MyHomeLink';
import { selectIsRealtor } from '../../selectors/user';

export const MyHomeDesktopContainer: React.FC = () => {
  const [isMainPage, setIsMainPage] = React.useState(false);

  const [isOpen, setOpen] = React.useState(false);
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  const isRealtor = useSelector(selectIsRealtor);

  const handleClose = React.useCallback(() => {
    if (!isOpen) {
      setOpen(false);
      setTooltipCloseCookies();
    }
  }, [tooltipRef]);

  const handleClick = React.useCallback(() => {
    handleClose();
    trackingMyHomeIconClick();
  }, [tooltipRef]);

  React.useEffect(() => {
    const tooltipCloseFromCookie = getTooltipCloseFromCookie();

    setOpen(!tooltipCloseFromCookie);
  }, [tooltipRef]);

  React.useEffect(() => {
    // Показываем тултип только для главной страницы
    setIsMainPage(window.location.pathname === '/');
  }, []);

  if (isRealtor) {
    return null;
  }

  return (
    <MyHomeLinkLayout onClick={handleClick}>
      <span ref={tooltipRef}>Мой дом</span>

      {isMainPage && (
        <CustomTooltip
          open={isOpen}
          onClose={handleClose}
          content="Вся информация для выгодной продажи, сдачи и поиска нового жилья"
          placement="bottom"
          theme="blue"
          arrow={true}
          closeButton
          anchorRef={tooltipRef}
          maxWidth={320}
        />
      )}
    </MyHomeLinkLayout>
  );
};
