import { Close16 } from '@cian/ui-kit/prev/icons';
import { ArticleHeading2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './AuthenticationAlert.css';

interface IAuthenticationAlertProps {
  text: string;
  onClose(): void;
}

export function AuthenticationAlert({ text, onClose }: IAuthenticationAlertProps) {
  return (
    <>
      <div className={styles['alert']}>
        <ArticleHeading2 textAlign="center">{text}</ArticleHeading2>
        <button role="button" aria-label="Закрыть" className={styles['close']} onClick={onClose}>
          <Close16 />
        </button>
      </div>
      <div className={styles['alert-padding']} />
    </>
  );
}
