import { setCookie } from '@cian/utils/lib/browser/cookie';

import { SESSION_CIAN_APP_TOOLTIP_KEY } from './constants';

export const setTooltipCloseCookies = () => {
  const currentDate = new Date();
  const dateThroughOneYear = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1));

  setCookie(SESSION_CIAN_APP_TOOLTIP_KEY, `1`, {
    expires: dateThroughOneYear,
    secure: false,
  });
};
