import cn from 'classnames';
import * as React from 'react';

import * as styles from './AuthenticationModal.css';

type TAuthenticationContainerIndent = 'M' | 'L';

const INDENT_TO_CLASS: { [key in TAuthenticationContainerIndent]: string } = {
  M: styles['middle-container'],
  L: styles['large-container'],
};

interface IAuthenticationModalProps {
  children: React.ReactNode;
  indent?: TAuthenticationContainerIndent;
}

export const AuthenticationModal: React.FC<IAuthenticationModalProps> = ({ children, indent = 'L' }) => {
  const classNames = cn(styles['container'], INDENT_TO_CLASS[indent]);

  return (
    <div className={classNames} data-mark="Authentication">
      {children}
    </div>
  );
};
