import classNames from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';

interface IHeaderLayoutProps extends React.PropsWithChildren {
  noResize?: boolean;
  content: React.ReactNode;
  navbar: React.ReactNode;
}

export const HeaderLayout: React.FC<IHeaderLayoutProps> = ({ noResize, content, navbar, children }) => {
  return (
    <div className={classNames(styles['wrapper'], noResize && styles['static'])}>
      <div className={styles['inner']}>{content}</div>
      <div className={styles['inner']}>{navbar}</div>

      {children}
    </div>
  );
};
