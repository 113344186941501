import classNames from 'classnames';
import * as React from 'react';

import * as styles from './Badge.css';

interface IBadgeProps {
  pill?: boolean;
  children: React.ReactNode;
}

export const Badge: React.FC<IBadgeProps> = ({ pill, children }) => {
  return <span className={classNames(styles['container'], { [styles['container--pill']]: pill })}>{children}</span>;
};
