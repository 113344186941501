// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  IWebGetUserFavoritesRequest,
  TWebGetUserFavoritesModel,
  IMappers,
  TWebGetUserFavoritesResponse,
  IWebGetUserFavoritesResponse,
  IWebGetUserFavoritesResponseError,
} from './types';

export const defaultConfig: TWebGetUserFavoritesModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'favorites',
  pathApi: '/v1/web/get-user-favorites/',
  requestType: 'json',
} as TWebGetUserFavoritesModel;

export function createWebGetUserFavoritesModel(parameters: IWebGetUserFavoritesRequest): TWebGetUserFavoritesModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IWebGetUserFavoritesOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
  parameters: IWebGetUserFavoritesRequest;
}

export async function fetchWebGetUserFavorites<TResponse200, TResponse400>({
  httpApi,
  config,
  mappers,
  parameters,
}: IWebGetUserFavoritesOptions<TResponse200, TResponse400>): Promise<
  TWebGetUserFavoritesResponse | TResponse200 | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createWebGetUserFavoritesModel(parameters), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IWebGetUserFavoritesResponse);
    }
    if (statusCode === 400) {
      return mappers[400](response as IWebGetUserFavoritesResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TWebGetUserFavoritesResponse;
}
