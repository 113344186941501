import { EAuthenticationActionTypes } from '../../actions/authentication';
import { IAuthenticationState } from '../../types/authentication';
import { TReduxActions } from '../../types/redux';

const defaultState: IAuthenticationState = {
  isModalOpen: false,
  autoRefresh: true,
  alert: null,
  useDefaultView: false,
  viewData: null,
  source: null,
};

export function authenticationReducer(
  state: IAuthenticationState = defaultState,
  action: TReduxActions,
): IAuthenticationState {
  switch (action.type) {
    case EAuthenticationActionTypes.ModalOpen:
      return {
        ...state,
        isModalOpen: true,
        autoRefresh: action.payload.autoRefresh,
        alert: action.payload.alert,
        useDefaultView: Boolean(action.payload.useDefaultView),
        viewData: action.payload.viewData,
        source: action.payload.source || null,
      };

    case EAuthenticationActionTypes.ModalClose:
      return {
        ...state,
        isModalOpen: false,
        alert: null,
        useDefaultView: false,
      };
  }

  return state;
}
