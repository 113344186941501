import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './FeedbackFormTechProblems.css';

export const FeedbackFormTechProblems: React.VFC = () => {
  return (
    <div className={styles['container']}>
      <UIText2>
        Позвоните нам по телефону{' '}
        <a className={styles['link']} href="tel:8 (800) 555-32-18">
          8 (800) 555-32-18
        </a>{' '}
        с 9 до 21 по Мск или напишите на{' '}
        <a className={styles['link']} href="mailto:support@cian.ru">
          support@cian.ru
        </a>
      </UIText2>
      <UIText2>
        К сожалению, сроки ответа увеличены. Приносим извинения, мы делаем все возможное, чтобы быть с вами на связи.
      </UIText2>
      <UIText2>
        Кстати, в нашем{' '}
        <a className={styles['link']} href="https://cian.zendesk.com/hc/ru">
          справочном центре
        </a>{' '}
        есть ответы почти на все вопросы.
      </UIText2>
      <UIText2>Клиентская служба Циан</UIText2>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default FeedbackFormTechProblems;
