// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TDesktopDeleteAllNotificationsModel,
  IMappers,
  TDesktopDeleteAllNotificationsResponse,
  IDesktopDeleteAllNotificationsResponseError,
} from './types';

export const defaultConfig: TDesktopDeleteAllNotificationsModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'notification-center',
  pathApi: '/v1/desktop/delete-all-notifications/',
} as TDesktopDeleteAllNotificationsModel;

export function createDesktopDeleteAllNotificationsModel(): TDesktopDeleteAllNotificationsModel {
  return {
    ...defaultConfig,
  };
}

export interface IDesktopDeleteAllNotificationsOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
}

export async function fetchDesktopDeleteAllNotifications<TResponse400>({
  httpApi,
  config,
  mappers,
}: IDesktopDeleteAllNotificationsOptions<TResponse400>): Promise<
  TDesktopDeleteAllNotificationsResponse | TResponse400
> {
  const { statusCode, response, headers } = await httpApi.fetch(createDesktopDeleteAllNotificationsModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IDesktopDeleteAllNotificationsResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDesktopDeleteAllNotificationsResponse;
}
