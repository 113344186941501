export enum EAlertType {
  Favorites = 'favorites',
  Chats = 'chats',
  Hide = 'hide',
}

export interface IAuthenticationState {
  isModalOpen: boolean;
  autoRefresh: boolean;
  alert: EAlertType | null;
  // нужно, чтобы сохранить логику установки начальной вьюхи в контексте авторизации
  useDefaultView: boolean;
  viewData: unknown;
  // откуда была запрошена авторизация
  source: string | null;
}
