import { CustomTooltip } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './SpecialPromoDesktop.css';

interface ISpecialPromoDesktopProps {
  href: string;
  isPik: boolean;
  logoUrl?: string | null;
  title?: string;
  disclaimer?: string | null;

  onClick(): void;
}

export const SpecialPromoDesktop: React.FC<ISpecialPromoDesktopProps> = ({
  logoUrl,
  isPik,
  onClick,
  href,
  title,
  disclaimer,
}) => {
  const [isHintOpened, setHintOpened] = React.useState(false);
  const hintRef = React.useRef<HTMLDivElement>(null);

  return (
    <a
      data-testid="special-promo-logo"
      href={href}
      onClick={onClick}
      className={styles['link']}
      onMouseEnter={() => setHintOpened(true)}
      onMouseLeave={() => setHintOpened(false)}
    >
      {logoUrl && (
        <span
          ref={hintRef}
          className={classNames(styles['icon'], isPik && styles['icon-pik'])}
          style={{
            backgroundImage: `url(${logoUrl})`,
          }}
        />
      )}
      {title && <span className={styles['title']}>{title}</span>}
      {disclaimer && (
        <CustomTooltip open={isHintOpened} placement={'bottom-start'} content={disclaimer} anchorRef={hintRef} />
      )}
    </a>
  );
};
