import { combineReducers } from 'redux';

import { authenticationReducer } from './authentication';
import { cookiesReducer } from './cookies';
import { dealsReducer } from './deals';
import { developerBannerReducer } from './developerBanner';
import { dropdownLinksReducer, dropdownReducer } from './dropdown';
import { experimentsReducer } from './experiments';
import { favoritesReducer } from './favorites';
import { feedbackFormReducer } from './feedbackForm';
import { filtersUrlReducer } from './filtersUrl';
import { geoReducer } from './geo';
import { leadsReducer } from './leads';
import { logoutReducer } from './logout';
import { messagesReducer } from './messages';
import { myHomeReducer } from './myHome';
import { navbarDefaultRubricsReducer } from './navbarDefaultRubrics';
import { noResizeReducer } from './noResize';
import { notificationsReducer } from './notifications';
import { pageReducer } from './page';
import { qualityLevelInfoReducer } from './qualityLevelInfo';
import { rubricsLinksReducer } from './rubricsLinks';
import { savedSearchesReducer } from './savedSearch';
import { servicesLinksReducer } from './servicesLinks';
import { specialPromosReducer } from './specialPromos';
import { suburbanBuildersAccountsListReducer } from './suburbanBuildersAccounts';
import { userReducer } from './user';
import { userAreaReducer } from './userArea';
import { IApplicationState, TReduxActions } from '../types/redux';

export const reducers = combineReducers<IApplicationState, TReduxActions>({
  authentication: authenticationReducer,
  cookies: cookiesReducer,
  notifications: notificationsReducer,
  favorites: favoritesReducer,
  geo: geoReducer,
  logout: logoutReducer,
  experiments: experimentsReducer,
  dropdown: dropdownReducer,
  feedbackForm: feedbackFormReducer,

  developerBanner: developerBannerReducer,
  navbarDefaultRubrics: navbarDefaultRubricsReducer,
  deals: dealsReducer,
  user: userReducer,
  userArea: userAreaReducer,
  messages: messagesReducer,
  savedSearches: savedSearchesReducer,
  leads: leadsReducer,
  rubricsLinks: rubricsLinksReducer,
  servicesLinks: servicesLinksReducer,
  specialPromos: specialPromosReducer,
  page: pageReducer,
  dropdownLinks: dropdownLinksReducer,
  noResize: noResizeReducer,
  filtersUrl: filtersUrlReducer,
  myHome: myHomeReducer,
  qualityLevelInfo: qualityLevelInfoReducer,
  suburbanBuildersAccountsList: suburbanBuildersAccountsListReducer,
});
