import { withPostViewAnalytics } from '@cian/postview-analytics-utils';
import * as React from 'react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { trackSpecialPromoClick } from './tracking';
import { SpecialPromoDesktop } from '../../components/SpecialPromoDesktop';
import { selectPageType } from '../../selectors/page';
import { selectCurrentSpecialPromo } from '../../selectors/specialPromos';
import { useApplicationContext } from '../../utils/applicationContext';

export function SpecialPromosDesktopContainer() {
  const {
    custom: { mainPageUrl },
  } = useApplicationContext();
  const pageType = useSelector(selectPageType);
  const currentSpecialPromo = useSelector(selectCurrentSpecialPromo);
  const isPik = useMemo(() => currentSpecialPromo?.name.toLocaleLowerCase() === 'пик', [currentSpecialPromo]);
  const handleSpecialPromoClick = React.useCallback(() => {
    if (!currentSpecialPromo) {
      return;
    }

    trackSpecialPromoClick(pageType, currentSpecialPromo.key);
  }, [currentSpecialPromo, pageType]);

  if (!currentSpecialPromo) {
    return null;
  }

  const props = {
    title: isPik ? '' : currentSpecialPromo.name,
    href: currentSpecialPromo.desktopUrl,
    logoUrl: currentSpecialPromo.desktopLogoUrl,
    disclaimer: currentSpecialPromo.disclaimer,
    isPik,
    onClick: handleSpecialPromoClick,
  };

  if (isPik) {
    const SpecialPromoDesktopWithPostView = withPostViewAnalytics(SpecialPromoDesktop, props, {
      banner: {
        id: 9,
        name: 'Special_project',
        place: 'top_menu_logo',
        hitUrl: `https:${mainPageUrl}${currentSpecialPromo.desktopUrl}`,
        extra: {
          offerType: 'builder',
        },
      },
    });

    return <SpecialPromoDesktopWithPostView />;
  }

  return <SpecialPromoDesktop {...props} />;
}
