import { BellStroke16 } from '@cian/ui-kit/prev/icons';
import { useDeviceType } from '@cian/ui-kit/responsive';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackNotificationIconClick } from './tracking';
import { openNotificationsModal } from '../../actions/notifications/openModal';
import { Utility } from '../../components/Utility';
import { IApplicationState } from '../../types/redux';
import { NotificationCenterContainer } from '../NotificationCenterContainer';

export const UtilityNotificationsContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const dispatch = useDispatch();

  const notificationsLink = useSelector<IApplicationState, string | undefined>(
    state => state.servicesLinks.notifications || undefined,
  );
  const notificationsCount = useSelector<IApplicationState, number>(state => state.notifications.count);
  const isOpen = useSelector<IApplicationState, boolean>(state => state.notifications.isNotificationsModalOpen);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>) => {
      if (deviceType === 'desktop') {
        event.preventDefault();
        dispatch(openNotificationsModal(true));
      }

      trackNotificationIconClick();
    },
    [deviceType, dispatch],
  );

  const handleClose = React.useCallback(() => {
    dispatch(openNotificationsModal(false));
  }, [dispatch]);

  if (!notificationsLink && deviceType !== 'desktop') {
    return null;
  }

  return (
    <>
      <Utility
        data-name="UtilityNotificationsContainer"
        title="Уведомления"
        unread={Boolean(notificationsCount)}
        icon={BellStroke16}
        href={notificationsLink}
        onClick={handleClick}
      />
      {isOpen && <NotificationCenterContainer onClose={handleClose} />}
    </>
  );
};
