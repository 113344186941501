// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */
import type { IHttpApi, IHttpApiFetchConfig } from '@cian/http-api';
import type {
  TDesktopMarkAllAsReadModel,
  IMappers,
  TDesktopMarkAllAsReadResponse,
  IDesktopMarkAllAsReadResponseError,
} from './types';

export const defaultConfig: TDesktopMarkAllAsReadModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  hostType: 'api',
  method: 'POST',
  microserviceName: 'notification-center',
  pathApi: '/v1/desktop/mark-all-as-read/',
} as TDesktopMarkAllAsReadModel;

export function createDesktopMarkAllAsReadModel(): TDesktopMarkAllAsReadModel {
  return {
    ...defaultConfig,
  };
}

export interface IDesktopMarkAllAsReadOptions<TResponse400> {
  httpApi: IHttpApi;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse400>;
}

export async function fetchDesktopMarkAllAsRead<TResponse400>({
  httpApi,
  config,
  mappers,
}: IDesktopMarkAllAsReadOptions<TResponse400>): Promise<TDesktopMarkAllAsReadResponse | TResponse400> {
  const { statusCode, response, headers } = await httpApi.fetch(createDesktopMarkAllAsReadModel(), config);
  if (mappers && statusCode in mappers) {
    if (statusCode === 400) {
      return mappers[400](response as IDesktopMarkAllAsReadResponseError);
    }
  }
  return {
    statusCode,
    response,
    headers,
  } as TDesktopMarkAllAsReadResponse;
}
