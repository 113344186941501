// Этот файл сгенерирован @cian/swagger-generator
// не вносите в него ручные изменения, иначе они будут утеряны
/* eslint-disable */

export interface INotificationForDesktopSchema {
  /** Идентификатор уведомления. Автоинкремент **/
  id: number;
  /** Флаг прочитанности уведомления в ЦУ **/
  isRead: boolean;
  /** Ссылка на фото в уведомлении **/
  mediaUrl?: string;
  /** Тип уведомления **/
  notificationType: ENotificationType;
  /** Дополнительные данные для веб-пуша **/
  payload?: object;
  /** Текст уведомления **/
  text: string;
  /** Название уведомления **/
  title: string;
  /** Дата обновления уведомления, для отображения на вебе **/
  updateDatetime: string;
  /** Timestamp обновления уведомления. По этому полю построен keyset для пагинации **/
  updateTimestamp: number;
  /** Ссылка для перехода по клику **/
  url: string;
  /** Уведомление создано с отправкой мобильного пуша. Требуется для аналитики **/
  withWebPush?: boolean;
}
export enum ENotificationType {
  /** Напоминание о брошеной форме подачи **/
  'AbandonedAddform' = 'abandonedAddform',
  /** Пуш о новом комментарии к статье **/
  'ArticleComment' = 'articleComment',
  /** Пуш о новом комментарии к блогу **/
  'BlogComment' = 'blogComment',
  /** Пуш о новом посте в блоге **/
  'BlogPost' = 'blogPost',
  /** Пуш о новых сообщениях за прошлый день в чате **/
  'ChatsMany' = 'chatsMany',
  /** Пуш о новом сообщении в чате **/
  'ChatsOne' = 'chatsOne',
  /** Пуш о новых рекомендациях в циан-помогаторе **/
  'CianChatBotDailyRecommendations' = 'cianChatBotDailyRecommendations',
  /** Пуши по сервисам и возможностям ЦИАН **/
  'CianServices' = 'cianServices',
  /** Пуш о готовности отчета в коммерческой **/
  'CommercialGeoAnalytics' = 'commercialGeoAnalytics',
  /** Истек срок публикации объявления **/
  'DeactivatedAnnouncement' = 'deactivatedAnnouncement',
  /** Пуши об успешном прохождении скоринга арендатором **/
  'DealRentScoringCompleted' = 'dealRentScoringCompleted',
  /** Пуши о проваленном прохождении скоринга арендатором **/
  'DealRentScoringFailed' = 'dealRentScoringFailed',
  /** Универсальный тип пушей для сделки в продаже **/
  'DealSaleUniversal' = 'dealSaleUniversal',
  /** Пуши по спросу **/
  'Demand' = 'demand',
  /** Пуш по пакетам заявок спроса **/
  'DemandMessagePackage' = 'demandMessagePackage',
  /** Новая версия пушей по спросу **/
  'DemandNew' = 'demandNew',
  /** Пуш об изменении цены дубля **/
  'DuplicatePriceChanged' = 'duplicatePriceChanged',
  /** Цена изменилась в избранном объявлении **/
  'FavoritePriceChanged' = 'favoritePriceChanged',
  /** Ответ от КС в HDE чате **/
  'HdeChatAnswer' = 'hdeChatAnswer',
  /** Пуш по последнему поиску **/
  'LastSearch' = 'lastSearch',
  /** Пуш о новом пропущенном звонке **/
  'MissedCall' = 'missedCall',
  /** Пуш о новых пропущенных звонках **/
  'MissedCalls' = 'missedCalls',
  /** Пуши о новых анкетах для обьявления в сделке **/
  'NewProfileForOfferInDeal' = 'newProfileForOfferInDeal',
  /** Пуш о новом запросе на показ объекта недвижимости **/
  'NewRealtyDemonstration' = 'newRealtyDemonstration',
  /** Пуш относящийся к отзывам по ЖК (новые отзывы) **/
  'NewbuildingReviews' = 'newbuildingReviews',
  /** Пуш о новом комментарии к новости **/
  'NewsComment' = 'newsComment',
  /** Не поддерживаемый тип пуша в мобильных приложениях. Нужен для тестирования - не удалять. **/
  'NotSupportedPush' = 'notSupportedPush',
  /** Пуш найден новый дубль объявления **/
  'OfferNewDuplicateFound' = 'offerNewDuplicateFound',
  /** Пуш объявлений в домах из избранного **/
  'OffersFavoriteHouse' = 'offersFavoriteHouse',
  /** Пуши для онбординга-книжки **/
  'Onbording' = 'onbording',
  /** Пуш об оценке на калькулятор недвижимости **/
  'PriceEstimator' = 'priceEstimator',
  /** Пуш о новом вопросе риэлтору **/
  'Question' = 'question',
  /** Пуш о новом ответе на вопрос риэлтору **/
  'QuestionAnswer' = 'questionAnswer',
  /** Рекомендованные объявления **/
  'RecommendedOffers' = 'recommendedOffers',
  /** Пуш-напоминание о новом показе объекта недвижимости **/
  'RemindNewRealtyDemonstration' = 'remindNewRealtyDemonstration',
  /** Пуш-вопрос состоялся ли показ объекта недвижимости **/
  'RemindPlannedRealtyDemonstration' = 'remindPlannedRealtyDemonstration',
  /** Оценка специалиста **/
  'ReviewSpecialist' = 'reviewSpecialist',
  /** Пуш при новой информации в отзывах **/
  'Reviews' = 'reviews',
  /** Закончилась проверка собственника в Росреестре **/
  'RosreestrCheckCompleted' = 'rosreestrCheckCompleted',
  /** Пуши о прохождении проверки арендатора в безопасной аренде (успешное или неуспешное) **/
  'SafeRentScoring' = 'safeRentScoring',
  /** Новое объявление в сохраненном поиске **/
  'SavedSearchNewOffer' = 'savedSearchNewOffer',
  /** Пуш с данным онбординга из mobile-app-onboarding **/
  'ShowOnboarding' = 'showOnboarding',
  /** Пуши вместо СМС **/
  'SmsPush' = 'smsPush',
  /** Закончилась проверка пользователя в SumSub **/
  'SumsubCheckCompleted' = 'sumsubCheckCompleted',
  /** Смена типа аккаунта **/
  'SwitchAccountType' = 'switchAccountType',
  /** Тестовый тип пуша, нужен чтобы отличать его от других в статистиках по доставке и т.д **/
  'TestNotification' = 'testNotification',
  /** Универсальные уведомления **/
  'UniversalNotification' = 'universalNotification',
  /** Велком пуши при регистрации пользователя **/
  'Welcome' = 'welcome',
  /** Пуши от zendesk **/
  'Zendesk' = 'zendesk',
}
