import * as React from 'react';

import { selectRubricsLinks } from './selectRubricsLinks';
import { RUBRIC_NAMES } from '../../constants/navbar/linkNames';
import { MobileAppDesktopContainer } from '../../containers/MobileAppDesktopContainer';
import { MyHomeDesktopContainer } from '../../containers/MyHomeDesktopContainer';
import { INavbarLink } from '../../types/navbar';
import { IApplicationState } from '../../types/redux';
import { ERubric, IRubricsLinksState } from '../../types/rubricsLinks';
import { selectNavbarDefaultRubrics } from '../navbarDefaultRubrics';
import { selectIsSuburbanFlightRkActive } from '../suburbanFlightRk';

export function selectNavbarRubrics(state: IApplicationState): INavbarLink[] {
  const rubricLinks = selectRubricsLinks(state);
  const navbarDefaultRubrics = selectNavbarDefaultRubrics(state);

  const isSuburbanFlightRkActive = selectIsSuburbanFlightRkActive(state);

  const { activeRubric, hoveredRubric } = state.dropdown;

  const navBarLinks: INavbarLink[] = [];

  for (const rubric of navbarDefaultRubrics) {
    const navBarLink: INavbarLink = {
      hovered: hoveredRubric === rubric,
      active: activeRubric === rubric,
      rubric,
      title: RUBRIC_NAMES[rubric],
    };

    if (rubric === ERubric.Countryside && isSuburbanFlightRkActive) {
      navBarLink.title = 'Дома и участки';
      navBarLink.url = '/zagorod';
    }

    if (rubric === ERubric.Services) {
      navBarLinks.push({
        ...navBarLink,
        hovered: false,
        active: false,
        rubric: ERubric.MyHome,
        title: RUBRIC_NAMES.myHome,
        component: <MyHomeDesktopContainer />,
      });
    }

    if (rubric === ERubric.CianApp) {
      navBarLink.component = <MobileAppDesktopContainer />;
    }

    if (isNotSyntheticRubric(rubric, rubricLinks) && rubricLinks[rubric]) {
      navBarLink.url = rubricLinks[rubric];
    }

    navBarLinks.push(navBarLink);
  }

  return navBarLinks;
}

function isNotSyntheticRubric(rubric: string, rubricLinks: IRubricsLinksState): rubric is keyof IRubricsLinksState {
  return rubricLinks.hasOwnProperty(rubric);
}
