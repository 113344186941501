import { logOut } from '@cian/auth';

import { logoutFailure } from './logoutFailure';
import { logoutRequest } from './logoutRequest';
import { logoutSuccess } from './logoutSuccess';
import { TThunkAction } from '../../types/redux';

export const logoutUser = (): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const logoutUrl = context.config.get<string>('headerFrontend.logOutBaseUrl') || undefined;

    dispatch(logoutRequest());
    const { logger } = context;

    try {
      await logOut(logoutUrl);
      dispatch(logoutSuccess());
      window.location.reload();
    } catch (error) {
      logger.error(error, {
        domain: 'logoutUser',
      });
      dispatch(logoutFailure());
    }
  };
};
