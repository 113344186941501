import { Close12 } from '@cian/ui-kit/prev/icons';
import { useDeviceType } from '@cian/ui-kit/responsive';
import { UIText1, UIText2 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './MergeNotification.css';
import logoMLSN from './assets/logo_mlsn.svg';
import logoN1 from './assets/logo_n1.svg';
import { EMergeType } from './types';

interface IMergeNotificationProps {
  type: EMergeType;
  onClose(): void;
}

const mergeTypesMap: { [key in EMergeType]: { image: string; name: string } } = {
  [EMergeType.N1]: {
    image: logoN1,
    name: 'N1',
  },
  [EMergeType.MLSN]: {
    image: logoMLSN,
    name: 'МЛСН',
  },
};

const MergeNotificationComponent: React.FC<IMergeNotificationProps> = ({ type, onClose }) => {
  const deviceType = useDeviceType();
  const merge = React.useMemo(() => mergeTypesMap[type], [type]);

  const handleClose = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();

      onClose();
    },
    [onClose],
  );

  const UIText = deviceType === 'phone' ? UIText2 : UIText1;

  return (
    <div className={styles['container']}>
      <div className={styles['wrapper']}>
        <div className={styles['logotype']} style={{ backgroundImage: `url(${merge.image})` }} />
        <div className={styles['text']}>
          <UIText>
            Сайт и приложение {merge.name} не работают, мы объединились. Добро пожаловать на Циан! Здесь всё то же
            самое,{' '}
            <span className={styles['nowrap']}>
              но лучше <span className={styles['emoji']} />
            </span>
          </UIText>
        </div>
        <button className={styles['close']} title="Закрыть" onClick={handleClose}>
          <Close12 display="block" />
        </button>
      </div>
    </div>
  );
};

export const MergeNotification = React.memo(MergeNotificationComponent);
