import { useDeviceType } from '@cian/ui-kit';
import { Sravnenie16 } from '@cian/ui-kit/prev/icons';
import * as React from 'react';

import { trackingCompareIconClick } from './tracking';
import { Utility } from '../../components/Utility';

export const UtilityCompareContainer: React.FC = () => {
  const deviceType = useDeviceType();
  const handleClick = React.useCallback(trackingCompareIconClick, []);

  if (deviceType === 'phone') {
    return null;
  }

  return (
    <Utility
      data-name="UtilityCompareContainer"
      title="Сравнение объектов"
      icon={Sravnenie16}
      href="/compare/"
      onClick={handleClick}
    />
  );
};
