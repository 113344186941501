import { IRubricsLinksState } from '../../types/rubricsLinks';
import { createStaticReducer } from '../../utils/redux/createStaticReducer';

export const rubricsLinksReducer = createStaticReducer<IRubricsLinksState>({
  askAgent: null,
  businessAnalytics: null,
  businessCenters: null,
  commercial: null,
  howToSaleSecondaryApartment: null,
  investCalculator: null,
  magazine: null,
  magazineAnalytics: null,
  magazineApartmentEvaluation: null,
  magazineDisountMortgage: null,
  magazineDocuments: null,
  magazineMortgage: null,
  magazineNewbuildingVsSecondary: null,
  magazineOnlineDeal: null,
  magazineRent: null,
  magazineSuburban: null,
  marketAnalytics: null,
  mortgage: null,
  mortgageCalculator: null,
  mortgageCenter: null,
  mortgageConsumerCredit: null,
  mortgageGovHelp: null,
  mortgagePersonalOffers: null,
  mortgageZeroOne: null,
  mortgageBrokerCalculator: null,
  mortgageBrokerPromo: null,
  newbuildingPromosUrl: null,
  newbuildingServicesKpn: null,
  rent: null,
  rentCottage: null,
  rentCoworking: null,
  rentDaily: null,
  rentOffice: null,
  rentRoom: null,
  rentShoppingArea: null,
  rentWarehouse: null,
  residentialComplexes: null,
  residentialComplexesCalculator: null,
  residentialComplexesCatalog: null,
  sale: null,
  saleApartment: null,
  saleCottage: null,
  saleLandplot: null,
  saleOffice: null,
  saleReadyBusiness: null,
  saleResidentialComplexesApartment: null,
  saleRoom: null,
  saleSecondaryApartment: null,
  saleShoppingArea: null,
  saleWarehouse: null,
  shoppingCenters: null,
  evaluation: null,
  villages: null,
  warehouses: null,
  suburbanMortgage: null,
  realtorSelection: null,
  promotions: null,
  dealMain: null,
  legalCheck: null,
  countryside: null,
  suburban: null,
  townhouseSale: null,
  landplotSale: null,
  cottageSale: null,
  countrysideRent: null,
  countrysideKp: null,
  houseSale: null,
  landSale: null,
  rentAddform: null,
  saleAddform: null,
  suburbanAddform: null,
  suburbanBuilders: null,
  suburbanBuildersProjects: null,
  suburbanMortgageLanding: null,
  suburbanTechInspection: null,
  buildingApplication: null,
});
