import { EHeaderStateActionType } from './types';
import { IHeaderData } from '../../headerData';
import { getHeaderData } from '../../services/getHeaderData';
import { getMyHomeIsExists } from '../../services/getMyHomeIsExists';
import { getNotificationsCount } from '../../services/getNotificationsCount';
import { getUnreadMessages } from '../../services/getUnreadMessages';
import { getUserFavorites } from '../../services/getUserFavorites';
import { TThunkAction } from '../../types/redux';
import { getLocationIdFromCookie } from '../../utils/cookie';
import { actionGenerator } from '../../utils/redux/actionGenerator';
import { updateUserFavorites } from '../favorites';
import { updateMessagesCount } from '../messages';
import { updateIsMyHomeExist } from '../myHome';
import { updateNotificationsCount } from '../notifications/updateCount';

export const updateHeaderData = actionGenerator<EHeaderStateActionType.Update, IHeaderData>(
  EHeaderStateActionType.Update,
);

export const updateState =
  (authenticated: boolean = true): TThunkAction<Promise<void>> =>
  async (dispatch, getState, context) => {
    try {
      const [headerData, notificationsCount, myHomeIsExist, favorites, messagesCount] = await Promise.all([
        getHeaderData(context, { subdomain: context.custom.subdomain, locationId: getLocationIdFromCookie(context) }),
        getNotificationsCount(context),
        getMyHomeIsExists(context),
        getUserFavorites(context),
        getUnreadMessages(context, authenticated),
      ]);

      dispatch(updateHeaderData(headerData));
      dispatch(updateNotificationsCount(notificationsCount));
      dispatch(updateIsMyHomeExist(myHomeIsExist));
      dispatch(updateUserFavorites(favorites));
      dispatch(updateMessagesCount(messagesCount));
    } catch (e) {
      context.telemetry.increment('actions.refresh-state.fail');
      context.logger.warning(e, {
        domain: 'actions.headerState.refreshState',
      });
    }
  };
