import { HttpBadStatusCodeError } from '@cian/peperrors/shared';

import { getNotificationsListFailed } from './getNotificationsListFailed';
import { getNotificationsListLoading } from './getNotificationsListLoading';
import { getNotificationsListSucceed } from './getNotificationsListSucceed';
import {
  fetchDesktopGetNotifications,
  TDesktopGetNotificationsResponse,
} from '../../../repositories/notification-center/v1/desktop/get-notifications';
import { TThunkAction } from '../../../types/redux';

export const getNotificationsList = (lastUpdateTimestamp: number = 0): TThunkAction<Promise<void>> => {
  return async (dispatch, getState, context) => {
    const { httpApi, logger } = context;

    dispatch(getNotificationsListLoading());
    try {
      const response: TDesktopGetNotificationsResponse = await fetchDesktopGetNotifications({
        httpApi,
        parameters: {
          lastUpdateTimestamp,
          limit: 5,
        },
      });

      if (response.statusCode !== 200) {
        throw new HttpBadStatusCodeError(
          'Не удалось загрузить список уведомлений',
          response.statusCode,
          'actions.notifications.getNotificationsList',
        );
      }

      dispatch(getNotificationsListSucceed(response.response.notifications));
    } catch (ex) {
      logger.error(ex, {
        domain: 'actions.notifications.getNotificationsList',
      });

      dispatch(getNotificationsListFailed());
    }
  };
};
