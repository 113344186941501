import { Outside } from '@cian/ui-kit/services/Outside';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './MobileNavigation.css';
import { Close } from './components/Close';

export interface IMobileNavigationProps {
  active?: boolean;
  onClose(): void;
  children: React.ReactNode;
}

export const MobileNavigation: React.FC<IMobileNavigationProps> = ({ children, active: isActive, onClose }) => (
  <div
    className={classNames(styles['container'], {
      [styles['container--active']]: isActive,
    })}
  >
    <Outside active={isActive} onOutside={onClose}>
      <div className={styles['wrapper']}>
        <Close onClick={onClose} />
        {children}
      </div>
    </Outside>
  </div>
);
