import {
  INotificationForDesktopSchema,
  ENotificationType,
} from '../../repositories/notification-center/entities/get_for_desktop/NotificationForDesktopSchema';

export interface INotificationsState {
  count: number;
  status: ENotificationsStatus;
  notifications: TNotification[];
  webPushStatus: EWebPushStatus;
  isNotificationsModalOpen: boolean;
}

export type TNotification = INotificationForDesktopSchema;

export { ENotificationType };

export enum ENotificationsStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export enum EWebPushStatus {
  Subscribing = 'loading',
  Subscribed = 'subscribed',
  Unsubscribed = 'unsubscribed',
}
