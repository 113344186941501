import { IDropdownLinksState } from '../../types/dropdown';

const defaultState: IDropdownLinksState = {
  map: null,
  helpCenter: null,
  developers: null,
  realtors: null,
  realtorsForum: null,
  cianCareer: null,
};

export function dropdownLinksReducer(state: IDropdownLinksState = defaultState): IDropdownLinksState {
  return state;
}
