import { InformationTooltipMobile } from '@cian/ui-kit';
import cx from 'classnames';
import * as React from 'react';

import * as styles from './Rubrics.css';
import { RubricItem } from './components/RubricItem';
import { TIrLink } from '../../../../types/irLink';
import { IRubricsLinksState } from '../../../../types/rubricsLinks';
import { ISpecialPromo } from '../../../../types/specialPromos';

interface IRubricsProps {
  rubrics: { title: string; url: string | null; key: keyof IRubricsLinksState | 'investors'; isHidden?: boolean }[];
  specialPromo?: ISpecialPromo | null;
  irLink: TIrLink | null;
  onRubricClick(rubricKey: keyof IRubricsLinksState | 'investors'): void;
  onSpecialPromoCLick(): void;
}

export const Rubrics: React.FC<IRubricsProps> = ({
  rubrics,
  specialPromo,
  irLink,
  onRubricClick,
  onSpecialPromoCLick,
}) => {
  return (
    <ul className={styles['container']}>
      {rubrics.map(({ title, key, url, isHidden }) => {
        if (isHidden) {
          return null;
        }

        return (
          <li key={key} className={styles['rubric-item']}>
            <RubricItem key={`rubric_${title}`} onClick={() => onRubricClick(key)} href={url as string}>
              {title}
            </RubricItem>
          </li>
        );
      })}
      {specialPromo && (
        <li data-name={'MobileSpecialPromoItem'} className={styles['rubric-item']}>
          <RubricItem href={specialPromo.mobileUrl} onClick={onSpecialPromoCLick}>
            <div className={styles['special-promo-item']}>
              <span>Новостройки от {specialPromo.nameGenitive}</span>
              {specialPromo.mobileLogoUrl && (
                <div
                  className={styles['special-promo-item-logo']}
                  style={{ backgroundImage: `url("${specialPromo.mobileLogoUrl}")` }}
                />
              )}
            </div>
          </RubricItem>
          {specialPromo.disclaimer && (
            <InformationTooltipMobile
              content={
                <div className={styles['tooltip-container']}>
                  <div className={cx(styles['hint'], styles['tooltip-icon'])}></div>
                  <div>{specialPromo.disclaimer}</div>
                </div>
              }
              portal={false}
            >
              <div data-name={'MobileSpecialPromoItemHint'} className={styles['hint']}></div>
            </InformationTooltipMobile>
          )}
        </li>
      )}
      {irLink && (
        <li data-name="IrLink" key={irLink.key} className={styles['rubric-item']}>
          <RubricItem key={`rubric_${irLink.title}`} href={irLink.link} onClick={() => onRubricClick(irLink.key)}>
            {irLink.title}
          </RubricItem>
        </li>
      )}
    </ul>
  );
};
